<template>
  <div class="container p-1 my-5 border">
  <!--
    <div class="row">
      <div>
          <h1>Your coordinates:</h1>
          <p>{{ myCoordinates.lat }} Latitude, {{ myCoordinates.lng }} Longitude</p>
      </div>
      <div class="col-md-6">
        <h1>Destination:</h1>
        <p>{{ mapCoordinates.lat }} Latitude, {{ mapCoordinates.lng }} Longitude</p>
      </div>
    </div>
--->
    <div class="flex justify-center">
      <div>
        <!--<div v-for="origin_address in stationDistance.origin_addresses" :key="origin_address.length">
          Origin: {{ origin_address }}
        </div>
        <div v-for="destination_address in stationDistance.destination_addresses" :key="destination_address.length">
          Destination: {{ destination_address }}
        </div> -->
        <div>
          Distance to Station: {{ destinationDistance }}
        </div>
        <div>
          Time to Station: {{ destinationTime }}
        </div>
<!--         <form id="distance_form">
          <div class="form-group">
              <label>Origin: </label>
              <input class="form-control" id="from_places" placeholder="Enter a location" />
              <input id="origin" name="origin" required="" type="hidden" />
          </div>

          <div class="form-group">
              <label>Destination: </label>
              <input class="form-control" id="to_places" placeholder="Enter a location" />
              <input id="destination" name="destination" required="" type="hidden" />
          </div>
          <br>
          <input class="btn btn-primary" type="submit" value="Calculate" @click.prevent="distanceUpdates" />
        </form> -->
      </div>
    </div>
<!---
      <div class="flex justify-center" id="result">
          <ul class="list-group">
              <li id="mile" class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
               ">
                Distance In Mile :
              </li>
              <li id="kilo" class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                ">
                Distance is Kilograms:
              </li>
                <li id="text" class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                ">
                IN TEXT:
              </li>
                <li id="minute" class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
                ">
                IN MINUTES:
                </li>
                <li id="from" class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
              ">
                          FROM:
                      </li>
                      <li id="to" class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
              ">
                          TO:
          </li>
        </ul>
      </div>

      <div class="col-md-12">
        <form>
          <h3>Add a new marker</h3>
          <label for="lat"></label>
          <input type="text" placeholder="Enter lat">
          <label for="lon"></label>
          <input type="text" placeholder="Enter lng">
        </form>
      </div>--->
    <section class="bg-fixed">
      <div id="map" class="absolute z-0" ></div>
      <input
      id="pac-input"
      class="controls"
      type="text"
      placeholder="Search Box"
      />
    </section>
  </div>   

</template>

<script>
// import axios from 'axios';

export default {
  name: 'MapView',
  data() {
    return {
      stationDistance: {},
      destinationDistance: null,
      destinationTime: null,
    }
  },
  props: {
    station: {
      type: Object,
      // required: true,
    },
    currentLat: {
      type: Number,
      // required: true,
    },
    currentLng: {
      type: Number,
      // required: true,
    },
  },
  created() {
    let that = this;
    // calculate distance
    // function calculateDistance() {
    //     let origin = {lat:40.444845,lng:-74.409195};
    //     let destination = {lat: this.station.coordinates.latitude, lng: this.station.coordinates.longitude};
    //     console.log('Origin distance: ', origin);
    //     console.log('Destination distance: ', destination);
    //     var service = new window.google.maps.DistanceMatrixService();
    //     service.getDistanceMatrix(
    //         {
    //             origins: [origin],
    //             destinations: [destination],
    //             travelMode: window.google.maps.TravelMode.DRIVING,
    //             unitSystem: window.google.maps.UnitSystem.IMPERIAL, // miles and feet.
    //             // unitSystem: google.maps.UnitSystem.metric, // kilometers and meters.
    //             avoidHighways: false,
    //             avoidTolls: false,
    //         },
    //         // callback
    //     );
    // }
    // get distance results
    // function callback(response, status) {
    //     if (status != window.google.maps.DistanceMatrixStatus.OK) {
    //         console.log('Error distance calculation', status);
    //     } else {
    //         var origin = response.originAddresses[0];
    //         console.log(origin);
    //         var destination = response.destinationAddresses[0];
    //         console.log(destination);
    //         if (response.rows[0].elements[0].status === "ZERO_RESULTS") {
    //             console.log("Better get on a plane. There are no roads between " +
    //                 origin +
    //                 " and " +
    //                 destination
    //             );
    //         } else {
    //             var distance = response.rows[0].elements[0].distance;
    //             console.log(distance);
    //             var duration = response.rows[0].elements[0].duration;
    //             console.log(duration);
    //             console.log(response.rows[0].elements[0].distance);
    //             var distance_in_kilo = distance.value / 1000; // the kilom
    //             var distance_in_mile = distance.value / 1609.34; // the mile
    //             console.log(distance_in_kilo);
    //             console.log(distance_in_mile);
    //             // var duration_text = duration.text;
    //             // var duration_value = duration.value;
    //             // $("#mile").html(`Distance in Miles: ${distance_in_mile.toFixed(2)}`);
    //             // $("#kilo").html(`Distance in Kilometre: ${distance_in_kilo.toFixed(2)}`);
    //             // $("#text").html(`Distance in Text: ${duration_text}`); 
    //             // $("#minute").html(`Distance in Minutes: ${duration_value}`);
    //             // $("#from").html(`Distance From: ${origin}`);
    //             // $("#to").text(`Distance to: ${destination}`);
    //         }
    //     }
    // }
    function getLocation(){
        var returnObject = {};
        if('geolocation' in navigator){
            navigator.geolocation.getCurrentPosition(
            (position) => {
            returnObject["lat"] = position.coords.latitude;
            returnObject["lng"] = position.coords.longitude;
            initMap(returnObject.lat,returnObject.lng);
            });
            if(typeof lat ==='undefined' && typeof lng==='undefined'){
                return returnObject['lat'], returnObject['lng']
            }
        }
    }

    // if (this.currentLat != null && this.currentLng != null && this.station != null) {
    //   this.calculateDistance();
    // }
    if (this.currentLat != null && this.currentLng != null && this.station != null) {
      getLocation();
    }

    //Labels for the markers by clicking on the screen
    // const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    // let labelIndex =0;
     let markers = [];
    // let userDestination ={};

    //random lat and lng 
    // var njit = (40.742491, -74.178078);
    // var random2 = {lat:40.444845,lng:-74.409195};
    if (this.currentLat != null && this.currentLng != null && this.station != null) {
      var random2 = {lat: this.station.coordinates[0], lng: this.station.coordinates[1]};
    }

    //main google maps API
    function initMap(latitude, longitude) {
        // The user location 
        const userLocation = { lat: latitude, lng: longitude };
        // this.currentLocation.latitude = latitude;
        // this.currentLocation.longitude = longitude;
        // console.log('Current location: ', this.currentLocation);
        //The map, centered at Uluru
        const map = new window.google.maps.Map(document.getElementById("map"), {
          center: userLocation,
          zoom: 15,
        //mapTypeId: google.maps.mapTypeId.ROADMAP Not needed for now
        });
        // user marker
        new window.google.maps.Marker({position: userLocation,
        map,
        });
        
        // This event listener calls addMarker() when the map is clicked.
        // window.google.maps.event.addListener(map, "click", (event) => {
        // addMarker(event.latLng, map);
        // });

        window.google.maps.event.addListener( markers, 'click', function ( event ) {
          var latitude = document.getElementById( "maps_latitude" ).value = event.latLng.lat();
          var longitude = document.getElementById( "maps_longitude" ).value = event.latLng.lng();
          let userDestination = { lat: latitude, lng: longitude };
          console.log('User location: ', userDestination);
        });
        //userDestination = { lat: lat, lng: lng };

        // Create the search box and link it to the UI element.
        const input = document.getElementById("pac-input");
        const searchBox = new window.google.maps.places.SearchBox(input);
        //position in the map 
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
        
        });
        //array for markers 
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new window.google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }
        const icon = {
            url: place.icon,
            size: new window.google.maps.Size(81, 81),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(35, 35),
          };

          // Create a marker for each place.
          markers.push(
            new window.google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );
          //var location = google.maps.Marker({position:place.geometry.location});
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
      //console.log(markers[0].position);

      //directions here!
      let directionsService = new window.google.maps.DirectionsService();
      let directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map); // Existing map object displays directions
      // Create route from existing points used for markers
      const route = {
          origin: userLocation,
          destination: random2,
          travelMode: window.google.maps.TravelMode.DRIVING,
      }

      directionsService.route(route,
        function(response, status) { // anonymous function to capture directions
          if (status !== 'OK') {
            window.alert('Directions request failed due to ' + status);
            return;
          } else {
            directionsRenderer.setDirections(response); // Add route to the map
            var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
            if (!directionsData) {
              window.alert('Directions request failed');
              return;
            }
            else {
              if (directionsData.distance.text != null && directionsData.duration.text != null) {
                that.destinationDistance = directionsData.distance.text;
                that.destinationTime = directionsData.duration.text;
              }

              // document.getElementById('msg').innerHTML += " Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").";
            }
          }
        });
    }

    // function calculateAndDisplayRoute(directionsService, directionsRenderer) {
    //   directionsService
    //     .route({
    //       origin: {
    //         query: document.getElementById("start").value,
    //       },
    //       destination: {
    //         query: document.getElementById("end").value,
    //       },
    //       travelMode: google.maps.TravelMode.DRIVING,
    //     })
    //     .then((response) => {
    //       directionsRenderer.setDirections(response);
    //     })
    //     .catch((e) => window.alert("Directions request failed due to " + e));
    // }

    //function to integate more markers on the map where location is the center lat lng
    // function addMarker(location, map){
    //     new window.google.maps.Marker({
    //         position:location,//{ lat:latitude, lng:longitude},
    //         label: labels[labelIndex++ % labels.length],
    //         map:map,
    //         draggable:true
    //     });
    // }

    // let autocomplete;
    // function initAutocomplete(){
    //     autocomplete = new google.maps.places.Autocomplete(
    //         document.getElementById('autocomplete'),
    //         {
    //             types:['establishment'],
    //             componentRestrictions:{'country':['us']},
    //             fiels:['place_id','geometry', 'name']
    //         });
    //         autocomplete.addListener('place_changed',onPlaceChanged);
    // }

    // function onPlaceChanged(){
    //     var place = autocomplete.getPlace();
    //     if(!place.geometry){
    //         //user did not select a prediction; reset the input field
    //         document.getElementById('autocomplete').innerHTML = place.name;
    //     }else {
    //         //display details about the valid place
    //         document.getElementById('details').innerHTML=place.name;
    //     }
    // }

    // function initialize() {
    //     var address = (document.getElementById('pac-input'));
    //     var autocomplete = new google.maps.places.Autocomplete(address);
    //     autocomplete.setTypes(['geocode']);
    //     google.maps.event.addListener(autocomplete, 'place_changed', function() {
    //         var place = autocomplete.getPlace();
    //         if (!place.geometry) {
    //             return;
    //         }

    //     var address = '';
    //     if (place.address_components) {
    //         address = [
    //             (place.address_components[0] && place.address_components[0].short_name || ''),
    //             (place.address_components[1] && place.address_components[1].short_name || ''),
    //             (place.address_components[2] && place.address_components[2].short_name || '')
    //             ].join(' ');
    //     }
    //     /*********************************************************************/
    //     /* var address contain your autocomplete address *********************/
    //     /* place.geometry.location.lat() && place.geometry.location.lat() ****/
    //     /* will be used for current address latitude and longitude************/
    //     /*********************************************************************/
    //     document.getElementById('lat').innerHTML = place.geometry.location.lat();
    //     document.getElementById('long').innerHTML = place.geometry.location.lng();
    //     });
    // }
  },
  methods: {
    // calculate distance
    calculateDistance() {
      // console.log('Calculate distance');
      // const URL = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${this.currentLat},${this.currentLng}&destinations=${this.station.coordinates.latitude},${this.station.coordinates.longitude}&units=imperial&key=AIzaSyCSLoEe5amg_DqF7t-XQnFVtSrUFtksZS0`;
      // // const URL = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json?origins=${this.currentLat},${this.currentLng}&destinations=${this.station.coordinates.latitude},${this.station.coordinates.longitude}&units=imperial&key=AIzaSyCSLoEe5amg_DqF7t-XQnFVtSrUFtksZS0`;
      // axios
      //   .get(URL)
      //   .then(response => {
      //     console.log('Response: ', response.data);
      //     console.log('Distance: ', response.data.rows[0].elements[0].distance.text);
      //     console.log('Duration: ', response.data.rows[0].elements[0].duration.text);
      //     this.stationDistance = response.data;
      //     this.destinationDistance = response.data.rows[0].elements[0].distance.text;
      //     this.destinationTime = response.data.rows[0].elements[0].duration.text;
      //     console.log('Station', this.stationDistance.destination_addresses[0]);
      //   })
      //   .catch(error => {
      //     console.log('Error: ', error.message);
      //   });
    },
  //       origin = ("#origin").val();
  //       destination = ("#destination").val();
  //       var service = new google.maps.DistanceMatrixService();
  //       service.getDistanceMatrix(
  //           {
  //               origins: [origin],
  //               destinations: [destination],
  //               travelMode: google.maps.TravelMode.DRIVING,
  //               unitSystem: google.maps.UnitSystem.IMPERIAL, // miles and feet.
  //               // unitSystem: google.maps.UnitSystem.metric, // kilometers and meters.
  //               avoidHighways: false,
  //               avoidTolls: false,
  //           },
  //           callback()
  //       );
  //   },
  //   // get distance results
  //  callback(response, status) {
  //       if (status != google.maps.DistanceMatrixStatus.OK) {
  //           $("#result").html(err);
  //       } else {
  //           var origin = response.originAddresses[0];
  //           console.log(origin);
  //           var destination = response.destinationAddresses[0];
  //           console.log(destination);
  //           if (response.rows[0].elements[0].status === "ZERO_RESULTS") {
  //               $("#result").html("Better get on a plane. There are no roads between " +
  //                   origin +
  //                   " and " +
  //                   destination
  //               );
  //           } else {
  //               var distance = response.rows[0].elements[0].distance;
  //               //console.log(distance);
  //               var duration = response.rows[0].elements[0].duration;
  //               //console.log(duration);
  //               //console.log(response.rows[0].elements[0].distance);
  //               var distance_in_kilo = distance.value / 1000; // the kilom
  //               var distance_in_mile = distance.value / 1609.34; // the mile
  //               //console.log(distance_in_kilo);
  //               //console.log(distance_in_mile);
  //               var duration_text = duration.text;
  //               var duration_value = duration.value;
  //               $("#mile").html(`Distance in Miles: ${distance_in_mile.toFixed(2)}`);
  //               $("#kilo").html(`Distance in Kilometre: ${distance_in_kilo.toFixed(2)}`);
  //               $("#text").html(`Distance in Text: ${duration_text}`); 
  //               $("#minute").html(`Distance in Minutes: ${duration_value}`);
  //               $("#from").html(`Distance From: ${origin}`);
  //               $("#to").text(`Distance to: ${destination}`);
  //           }
  //       }
  //   },
    // print results on submit the form
        distanceUpdates() {
    // $("#distance_form").submit(function (e) {
    //     e.preventDefault();
    //         // add input listeners
    // google.maps.event.addDomListener(window, "load", function () {
    //     var from_places = new google.maps.places.Autocomplete(
    //         document.getElementById("from_places")
    //     );
    //     var to_places = new google.maps.places.Autocomplete(
    //         document.getElementById("to_places")
    //     );

    //     google.maps.event.addListener(
    //         from_places,
    //         "place_changed",
    //         function () {
    //             var from_place = from_places.getPlace();
    //             var from_address = from_place.formatted_address;
    //             $("#origin").val(from_address);
    //         }
    //     );

    //     google.maps.event.addListener(
    //         to_places,
    //         "place_changed",
    //         function () {
    //             var to_place = to_places.getPlace();
    //             var to_address = to_place.formatted_address;
    //             $("#destination").val(to_address);
    //         }
    //     );
    // });
      this.calculateDistance();
    // })
    }
  },
};
</script>

<style scoped>
.ui.button,
.dot.circle.icon{
  background-color: rgb(169, 85, 85);
  color: white;
}
  #map {
    /* width: 100vw; */
    height: 70vmin;
    padding: 2rem;
    background-color: grey;
    align-content: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
  }
#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

</style>


