<template>
  <div class="col-span-3">

    <div class="hidden lg:block p-6 bg-white rounded-md border border-gray-200 shadow-sm text-gray-900">
        <!-- Ride Steps -->
        <ul class="flex justify-around pb-4 border-b">
          <li class="flex flex-col items-center">
            <a href="index.html" class="bg-gray-500 text-white rounded-full px-4 py-2 mb-2 font-bold">1</a>
            <a href="index.html">Ride Requested</a>
          </li>
          <li class="flex flex-col items-center">
            <a href="#" class="bg-gray-700 text-white rounded-full px-4 py-2 mb-2 font-bold">2</a>
            <a href="#">Ride Accepted</a>
          </li>
          <li class="flex flex-col items-center">
            <a href="start.html" class="bg-gray-700 transition hover:bg-gray-500 text-white rounded-full px-4 py-2 mb-2 font-bold">3</a>
            <a href="start.html">Ride Started</a>
          </li>
          <li class="flex flex-col items-center">
            <a href="#" class="bg-gray-700 text-white rounded-full px-4 py-2 mb-2 font-bold">4</a>
            <a href="#">Ride In-Progress</a>
          </li>
          <li class="flex flex-col items-center">
            <a href="#" class="bg-gray-700 text-white rounded-full px-4 py-2 mb-2 font-bold">5</a>
            <a href="#">Ride Completed</a>
          </li>
        </ul>

        <!-- Request Form -->
        <div class="flex flex-col justify-center items-center py-6 px-10">        
          <vee-form class="w-full" :validation-schema="schema"
          @submit="ride" :initial-values="userData">
            <div class="container">
              <div class="md:flex md:items-center mb-3">
                <p class="text-gray-700 font-bold mb-1">
                  Please enter ride details.
                </p>
              </div>
            </div>
          
            <div class="md:flex md:flex-col mb-3">
              <div class="md:mb-2">
                <label class="block text-gray-700">Email</label>
              </div>
              <div class="md:w-full">
                <input
                type="text"
                autocomplete="username"
                name="email"
                class="
                block
                w-full
                py-2
                px-3
                text-gray-800
                border border-gray-300
                transition
                duration-500
                focus:outline-none focus:border-black
                rounded
                "
                placeholder="Enter Email"
                />
                <ErrorMessage class="text-red-600" name="email" />
              </div>
            </div>
  
            <div class="md:flex md:flex-col mb-3">
              <div class="md:mb-2">
                <label class="block text-gray-700">Pickup Location</label>
              </div>
              <div class="md:w-full">
                <input
                type="text"
                autocomplete="text"
                name="pickup"
                class="
                block
                w-full
                py-2
                px-3
                text-gray-800
                border border-gray-300
                transition
                duration-500
                focus:outline-none focus:border-black
                rounded
                "
                placeholder="Enter Pickup Location"
                />
                <ErrorMessage class="text-red-600" name="email" />
              </div>
            </div>
          
            <div class="md:flex md:flex-col mb-3">
              <div class="md:mb-2">
                <label class="block text-gray-700">
                  Destination City
                </label>
              </div>
              <div class="md:w-full">
                <input
                type="text"
                name="destination"
                class="
                block
                w-full
                py-2
                px-3
                text-gray-800
                border border-gray-300
                transition
                duration-500
                focus:outline-none focus:border-black
                rounded
                "
                placeholder="Enter Destination City"
                />
                <ErrorMessage class="text-red-600" name="destination" />
              </div>
            </div>
          
            <div class="md:flex md:flex-col mb-6">
              <div class="md:mb-2">
                <label class="block text-gray-700">
                  Trip Time
                </label>
              </div>
              <div class="md:w-full">
                <input
                type="datetime-local"
                name="triptime"
                class="
                block
                w-full
                py-2
                px-3
                text-gray-800
                border border-gray-300
                transition
                duration-500
                focus:outline-none focus:border-black
                rounded
                "
                placeholder="Enter Trip Time"
                />
                <ErrorMessage class="text-red-600" name="triptime" />
              </div>
            </div>
          
            <div class="md:flex md:items-center">
              <!-- <div class="md:w-full"></div> -->
              <div class="md:w-1/3">
              <button
                type="submit"
                :disabled="login_in_submission"
                class="
                block
                w-full
                bg-yellow-500
                text-white
                py-2
                px-3
                rounded
                transition
                font-bold
                hover:bg-yellow-600
                shadow-md
                mb-2
                ">
                Submit
              </button>
            </div>
          </div>
        </vee-form>
      </div>

    </div>

      <!-- Request Form -->
    
  </div>
</template>

<script>
export default {
  name: 'RideView',
}
</script>