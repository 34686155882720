import { createRouter, createWebHistory } from 'vue-router'
// import DashboardView from '@/views/DashboardView'
import RideView from '@/views/RideView'
import StationView from '@/views/StationView'
import VehiclesView from '@/views/VehiclesView'
import VehicleView from '@/views/VehicleView'
import SettingsView from '@/views/SettingsView'
import AboutView from '@/views/AboutView'
import ServicesView from '@/views/ServicesView'
import ServicesAdminView from '@/views/ServicesAdminView'

const routes = [
  {
    path: '/',
    name: 'DashboardView',
    // component: DashboardView,
    component: StationView,
  },
  {
    path: '/ride',
    name: 'RideView',
    component: RideView,
  },
  {
    path: '/station',
    name: 'StationView',
    component: StationView,
  },
  {
    path: '/vehicles',
    name: 'VehiclesView',
    component: VehiclesView,
  },
  {
    path: '/vehicle',
    name: 'VehicleView',
    component: VehicleView,
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView,
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
  },
  {
    path: '/services',
    name: 'ServicesView',
    component: ServicesView,
  },
  {
    path: '/servicesadmin',
    name: 'ServicesAdminView',
    component: ServicesAdminView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
