<template>

  <div id="background">
    <div v-show="mobile" class="w-5/6 m-auto">
      <!-- <SearchBar placeholder="Search charging stations" /> -->
      
    </div>
  </div>

  <!-- <div v-show="mobile" class="flex justify-center bg-white shadow-md border fixed bottom-0 w-full p-4"> -->
    <!-- <ButtonBasic @click="setStationsClicked" class="w-full" buttonText="See nearby stations" buttonColor="bg-blue-500" buttonTextColor="text-white" /> -->
  <!-- </div> -->

    <ScrollableMenu v-bind:is-mobile="mobile" />
  <!-- <ScrollableMenu v-bind:is-mobile="mobile" v-bind:is-clicked="seeStationsClicked" /> -->

</template>

<script>
import ScrollableMenu from '../components/ScrollableMenu.vue';

export default {
  name: 'StationView',
  components: {
    ScrollableMenu,
  },
  data() {
    return {
      mobile: null,
      seeStationsClicked: null,
    }
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 768) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
    setStationsClicked() {
      this.seeStationsClicked = true;
    },
  },
}  
</script>
