<template>
  <div id="side-menu" class="hidden lg:block px-8 text-gray-900">
    <ul>
      <li class="mb-4 transition hover:text-gray-500">   
        <router-link :to="{ name: 'DashboardView' }">
          <i class="fas fa-columns text-xl mr-3"></i>
          Dashboard
        </router-link>
      </li>
      <li class="mb-4 transition hover:text-gray-500">
        <router-link :to="{ name: 'RideView' }">
          <i class="fas fa-car-alt text-xl mr-3"></i>
          Request Ride
        </router-link>
      </li>
      <li class="mb-4 transition hover:text-gray-500">
        <router-link :to="{ name: 'VehiclesView' }">
          <i class="fas fa-bookmark text-xl mr-3"></i>
          Browse Vehicles
        </router-link>
      </li>
      <li class="mb-4 transition hover:text-gray-500">
        <router-link :to="{ name: 'SettingsView' }">
          <i class="fas fa-cog text-xl mr-3"></i>
          Settings
        </router-link>
      </li>
      <li class="mb-4 transition hover:text-gray-500">
        <router-link to='/logout'>
          <i class="fas fa-sign-out-alt text-xl mr-3"></i>
          Logout
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SideMenu',
}

</script>