<template>
  <header class="col-span-5 bg-gray-700 text-white w-full" :class="!stationView ? 'fixed top-0-500  z-10' : 'relative'">
    <nav id="nav" class="px-7 py-5 lg:mb-0">
      <div class="flex justify-between items-center grid grid-cols-5">
        <!-- Title -->
        <h2 class="font-bold uppercase text-2xl col-span-2 lg:col-span-1">{{ title }}</h2>
        <!-- Hamburger Icon (Mobile) -->
        <i @click="toggleMobileNav" v-show="mobile" class="fas fa-solid fa-bars cursor-pointer transition hover:text-gray-300 col-start-5 justify-self-end"></i>     
        <!-- Primary Navigation -->
        <ul v-show="!mobile" class="flex items-center col-span-3">
          <!-- Navigation Links -->
          <!-- Commenting to show only station view for now
          <li>
            <router-link :to="{ name: 'RideView' }" class="mr-8 text-white transition hover:text-yellow-500">Ride</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AboutView' }" class="mr-8 text-white transition hover:text-yellow-500">About</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ServicesView' }" class="mr-8 text-white transition hover:text-yellow-500">Services</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ServicesAdminView' }" class="mr-8 text-white transition hover:text-yellow-500">Services (Admin)</router-link>
          </li>
          <li>
            <router-link to="/logout" class="mr-8 text-white transition hover:text-yellow-500">Logout</router-link>
          </li>  -->
          <li>
            <router-link :to="{ name: 'StationView' }" class="mr-8 text-white transition hover:text-yellow-500">Station</router-link>
          </li>        
        </ul>  
      </div>
    </nav>
  </header>

  <transition name="mobile-nav">
    <!-- <ul v-show="mobileNav" class="flex justify-evenly items-center pb-4 mb-4 bg-gray-700"> -->
    <div v-show="mobileNav" class="fixed top-0 left-0 inset-y-0 w-1/2 bg-gray-700 z-20">
      <ul class="flex flex-col space-y-5 justify-center items-start px-5 py-8">
        <!-- Navigation Links -->
        <!-- Commenting to show only station view
        <li>
          <router-link :to="{ name: 'DashboardView' }" class="lg:mr-8 text-white transition hover:text-yellow-500">Ride</router-link>
          <ul class="flex flex-col space-y-1">
            <li>
              <router-link :to="{ name: 'DashboardView' }" class="ml-3 text-white transition hover:text-yellow-500">Dashboard</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'RideView' }" class="ml-3 text-white transition hover:text-yellow-500">Request Ride</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'VehiclesView' }" class="ml-3 text-white transition hover:text-yellow-500">Browse Vehicles</router-link>
            </li>
            <li>
              <router-link :to="{ name: '' }" class="ml-3 text-white transition hover:text-yellow-500">Settings</router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link :to="{ name: 'ServicesView' }" class="mr-8 text-white transition hover:text-yellow-500">Services</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ServicesAdminView' }" class="mr-8 text-white transition hover:text-yellow-500">Services (Admin)</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AboutView' }" class="lg:mr-8 text-white transition hover:text-yellow-500">About</router-link>
        </li>
        <li v-show="false">
          <router-link to="/logout" class="lg:mr-8 text-white transition hover:text-yellow-500">Logout</router-link>
        </li> -->
        <li>
          <router-link :to="{ name: 'StationView' }" class="mr-8 text-white transition hover:text-yellow-500">Station</router-link>
        </li>
      </ul>  
    </div>
  </transition>
</template>

<script>

export default {
  name: 'NavigationBar',
  props: {
    title: String,
    stationView: Boolean,
  },
  data() {
    return {
      mobile: null,
      mobileNav: null,
      windowWidth: null,
    }
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 768) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    }
  }
}

</script>

<style scoped>
  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: 0.3s ease-in-out;
  }

  .mobile-nav-enter-from,
  .mobile-nav-leave-to
  {
    transform: translateX(-400px);
  }

  .mobile-nav-enter-to {
    transform: translateX(0px);
  }
</style>