<template>
  <div class="col-span-3">

      <h3 class="ml-4 lg:ml-0 mb-2 font-bold text-3xl text-gray-900">Browse Vehicles</h3>
      <p class="ml-4 lg:ml-0 mb-4">See available vehicles for rent.</p>

      <!-- Search and Filter (mobile) -->

      <div class="lg:hidden m-4">
        <h3 class="mb-2 font-bold text-xl text-gray-900">Search Vehicles</h3>
        <input type="text" placeholder="Enter values" class="w-full border py-1 px-2 mb-2">
  
        <p class="mb-2">Filter By:</p>
        
        <div class="flex flex-col bg-white border p-2">
          <div class="flex items-center mb-1">
            <input type="radio" name="not-rented" class="mr-2">
            <p>Not rented</p>
          </div>
          <div class="flex items-center mb-1">
            <input type="radio" name="not-rented" class="mr-2">
            <p>Rented</p>
          </div>
          <div class="flex items-center mb-1">
            <input type="radio" name="not-rented" class="mr-2">
            <p>Lowest to highest rate</p>
          </div>
          <div class="flex items-center mb-1">
            <input type="radio" name="not-rented" class="mr-2">
            <p>Highest to lowest rate</p>
          </div>
          <div class="flex items-center mb-1">
            <input type="radio" name="not-rented" class="mr-2">
            <p>Closest Distance</p>
          </div>
          <div class="flex items-center mb-1">
            <input type="radio" name="not-rented" class="mr-2">
            <p>Farthest Distance</p>
          </div>      
        </div>
  
      </div>

      <div class="flex flex-col m-4 lg:m-0">
        <table class="table table-auto bg-white border text-center">
          <thead>
            <tr class="border uppercase text-gray-600">
              <th class="py-5">Vehicle</th>
              <th>Model</th>
              <th class="px-7 lg:px-0">Status</th>
              <th class="hidden lg:table-cell">Rate</th>
              <th class="hidden lg:table-cell">Service Area</th>
              <th class="pr-3 lg:pr-0">See Details</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border">
              <td class="p-5">Vehicle 1</td>
              <td>Tesla S</td>
              <td>
                <p class="bg-green-500 inline-block px-3 py-1 text-white font-bold rounded-full">Not Rented</p>
              </td>
              <td class="hidden lg:table-cell">$80</td>
              <td class="hidden lg:table-cell">08816</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
            <tr class="border">
              <td class="p-5">Vehicle 2</td>
              <td>BMW i4</td>
              <td>
                <p class="bg-red-500 inline-block px-3 py-1 text-white font-bold rounded-full">Rented</p>
              </td>
              <td class="hidden lg:table-cell">$140</td>
              <td class="hidden lg:table-cell">08816</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
            <tr class="border">
              <td class="p-5">Vehicle 3</td>
              <td>Audi E-Tron</td>
              <td>
                <p class="bg-green-500 inline-block px-3 py-1 text-white font-bold rounded-full">Not Rented</p>
              </td>
              <td class="hidden lg:table-cell">$100</td>
              <td class="hidden lg:table-cell">08813</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
            <tr class="border">
              <td class="p-5">Vehicle 4</td>
              <td>Hyundai Ioniq</td>
              <td>
                <p class="bg-green-500 inline-block px-3 py-1 text-white font-bold rounded-full">Not Rented</p>
              </td>
              <td class="hidden lg:table-cell">$95</td>
              <td class="hidden lg:table-cell">08813</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
            <tr class="border">
              <td class="p-5">Vehicle 5</td>
              <td>Kai E-Niro</td>
              <td>
                <p class="bg-green-500 inline-block px-3 py-1 text-white font-bold rounded-full">Not Rented</p>
              </td>
              <td class="hidden lg:table-cell">$76</td>
              <td class="hidden lg:table-cell">08813</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
            <tr class="border">
              <td class="p-5">Vehicle 6</td>
              <td>Mini Cooper SE</td>
              <td>
                <p class="bg-red-500 inline-block px-3 py-1 text-white font-bold rounded-full">Rented</p>
              </td>
              <td class="hidden lg:table-cell">$70</td>
              <td class="hidden lg:table-cell">08812</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
            <tr class="border">
              <td class="p-5">Vehicle 7</td>
              <td>Nissan Leaf</td>
              <td>
                <p class="bg-red-500 inline-block px-3 py-1 text-white font-bold rounded-full">Rented</p>
              </td>
              <td class="hidden lg:table-cell">$96</td>
              <td class="hidden lg:table-cell">08812</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
            <tr class="border">
              <td class="p-5">Vehicle 8</td>
              <td>Tesla Model X</td>
              <td>
                <p class="bg-green-500 inline-block px-3 py-1 text-white font-bold rounded-full">Not Rented</p>
              </td>
              <td class="hidden lg:table-cell">$115</td>
              <td class="hidden lg:table-cell">08810</td>
              <td>
                <router-link :to="{ name: 'VehicleView' }" class="transition hover:text-gray-500">
                  <i class="fas fa-eye"></i>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <!-- Filter & Search -->
    <div class="hidden lg:block pr-8">
      <h3 class="mb-2 font-bold text-xl text-gray-900">Search Vehicles</h3>
      <input type="text" placeholder="Enter values" class="w-full border py-1 px-2 mb-4">

      <p class="mb-2">Filter By:</p>
      
      <div class="flex flex-col bg-white border p-2">
        <div class="flex items-center mb-1">
          <input type="radio" name="not-rented" class="mr-2">
          <p>Not rented</p>
        </div>
        <div class="flex items-center mb-1">
          <input type="radio" name="not-rented" class="mr-2">
          <p>Rented</p>
        </div>
        <div class="flex items-center mb-1">
          <input type="radio" name="not-rented" class="mr-2">
          <p>Lowest to highest rate</p>
        </div>
        <div class="flex items-center mb-1">
          <input type="radio" name="not-rented" class="mr-2">
          <p>Highest to lowest rate</p>
        </div>
        <div class="flex items-center mb-1">
          <input type="radio" name="not-rented" class="mr-2">
          <p>Closest Distance</p>
        </div>
        <div class="flex items-center mb-1">
          <input type="radio" name="not-rented" class="mr-2">
          <p>Farthest Distance</p>
        </div>      
      </div>
    </div>

</template>

<script>
export default {
  name: 'VehiclesView',
};
</script>
