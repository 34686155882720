<template>

  <div class="col-span-3">
    
    <div class="mb-3">
      <h3 class="ml-4 lg:ml-0 mb-2 font-bold text-3xl text-gray-900">Vehicle Number</h3>
      <router-link :to="{ name: 'VehiclesView' }" class="transition hover:text-gray-500">
        <i class="fas fa-arrow-left"></i>
        Return to Browse Vehicles
      </router-link>
    </div>

    <img src="../assets/car1angle1.jpg" alt="car1" class="mb-2 px-4 lg:px-0">

    <div class="grid lg:grid-cols-5 grid-cols-3 gap-2 mx-4 lg:mx-0 mb-4 lg:mb-0" id="car1angles">
      <img src="../assets/car1angle1.jpg" alt="angle1" class="transition hover:opacity-90" data-original="car1angle1.jpg">
      <img src="../assets/car1angle2.jpg" alt="angle2" class="transition hover:opacity-90" data-original="car1angle2.jpg">
      <img src="../assets/car1angle3.jpg" alt="angle3" class="transition hover:opacity-90" data-original="car1angle3.jpg">
      <img src="../assets/car1angle4.jpg" alt="angle4" class="transition hover:opacity-90" data-original="car1angle4.jpg">
      <img src="../assets/car1angle5.jpg" alt="angle5" class="transition hover:opacity-90" data-original="car1angle5.jpg">
    </div>

    <!-- Modal -->

    <!-- <div class="bg-black bg-opacity-80 fixed top-0 left-0 w-full h-full hidden" id="modal">
      <i class="fas fa-times fa-2x text-white px-4 py-3 transition hover:text-gray-300"></i>
      <img src="./img/car1angle1.jpg" alt="angle1" class="absolute lg:h-4/6 scale-90 lg:scale-125 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" id="modal-img">        
    </div> -->

  </div>

  <!-- Vehicle Details -->
    <div class="flex flex-col mx-4 lg:mx-0 lg:pr-8">

    <table class="table-auto border w-full bg-white mb-4">
      <thead>
        <tr>
          <th class="py-3">Vehicle Number</th>
        </tr>
      </thead>
      <tr class="border">
        <td class="p-2">Vehicle Model:</td>
        <td>Tesla S</td>
      </tr>
      <tr class="border">
        <td class="p-2">Model Year:</td>
        <td>2019</td>
      </tr>
      <tr class="border">
        <td class="p-2">Purchase Year:</td>
        <td>2020</td>
      </tr>
      <tr class="border">
        <td class="p-2">Vehicle Fleet:</td>
        <td>Fleet 1</td>
      </tr>
    </table>

    <table class="table-auto border w-full bg-white mb-4">
      <thead>
        <tr>
          <th class="py-3">Vehicle Details</th>
        </tr>
      </thead>
      <tr class="border">
        <td class="p-2">Charge Capacity:</td>
        <td>350</td>
      </tr>
      <tr class="border">
        <td class="p-2">Passenger Capacity:</td>
        <td>6</td>
      </tr>
      <tr class="border">
        <td class="p-2">Mileage:</td>
        <td>30000</td>
      </tr>
    </table>     

    <table class="table-auto border w-full bg-white mb-4">
      <thead>
        <tr>
          <th class="py-3">Vehicle Status</th>
        </tr>
      </thead>
      <tr class="border">
        <td class="p-2">Rented:</td>
        <td>False</td>
      </tr>
      <tr class="border">
        <td class="p-2">Vehicle Rate:</td>
        <td>$80.00</td>
      </tr>
      <tr class="border">
        <td class="p-2">Service Area:</td>
        <td>08816</td>
      </tr>
    </table>  

    <table class="table-auto border w-full bg-white mb-4">
      <thead>
        <tr>
          <th class="py-3">Vehicle Service</th>
        </tr>
      </thead>
      <tr class="border">
        <td class="p-2">Vehicle Condition:</td>
        <td>Maintained well, few dents and scratches</td>
      </tr>
      <tr class="border">
        <td class="p-2">Vehicle Service Date:</td>
        <td>2021-03-22</td>
      </tr>
      <tr class="border">
        <td class="p-2">Front Condition:</td>
        <td>In good condition</td>
      </tr>
      <tr class="border">
        <td class="p-2">Driver Side Condition:</td>
        <td>Severe dents</td>
      </tr>
      <tr class="border">
        <td class="p-2">Passenger Side Condition:</td>
        <td>No visible issues</td>
      </tr>
      <tr class="border">
        <td class="p-2">Rear Condition:</td>
        <td>Some scratches</td>
      </tr>
    </table>
  </div>

</template>

<script>
export default {
  name: 'VehicleView',
};
</script>
