<template>
  <div class="lg:grid grid-cols-5 gap-4 pb-8">
    <NavigationBar 
      title="Ride EV"
    />
    <SideMenu  class="pt-24" v-show="isNotStationView" />
    <!-- <ScrollableMenu v-show="onStationView" /> -->
    <router-view class="pt-16"></router-view>
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar'
import SideMenu from './components/SideMenu'
// import ScrollableMenu from './components/ScrollableMenu'

export default {
  name: 'App',
  components: {
    NavigationBar,
    SideMenu,
    // ScrollableMenu
  },
  computed: {
    isNotStationView() {
      return this.$route.name !== 'StationView'
    },
    // onStationView() {
    //   return this.$route.name === 'StationView' 
    // }
  }
}
</script>

<style>
  #nav a.router-link-exact-active
  {
    color: #f59e0b;
  }

  #side-menu a.router-link-exact-active {
    color: #6b7280;
  }
</style>