import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyCBo3g3AsQ8oYSLLZo0Qy0avUMWCARhY6s",
//   authDomain: "ride-ev.firebaseapp.com",
//   projectId: "ride-ev",
//   storageBucket: "ride-ev.appspot.com",
//   messagingSenderId: "683363087125",
//   appId: "1:683363087125:web:de6c7b349515cdaa966f1c",
//   measurementId: "G-HP6611NYS0"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCOm_YBEmM4wQr3GLVmMWsKENTP8U2xgOo",
  authDomain: "rideevdev.firebaseapp.com",
  projectId: "rideevdev",
  storageBucket: "rideevdev.appspot.com",
  messagingSenderId: "787289595806",
  appId: "1:787289595806:web:e79412283d0f645f72008f"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export default db;