<template>
  
    <div class="col-span-3">
        <div :class="!detailsClicked ? 'grid grid-cols-2 gap-4' : 'w-full py-3 px-4 bg-white shadow-sm border rounded'">
            <div v-for="request in servicesArr" :key="request.id">
                <div v-show="!detailsClicked" class="flex flex-col">          
                    <div class="flex items-center justify-between font-bold text-gray-700 border-b pt-2 pb-3">
                        <p class="uppercase">{{ request.first + ' ' + request.last }}</p>
                        <div class="flex items-center">
                            <a @click="showEdit(request.id)" class="text-blue-500 underline mr-4 transition hover:text-blue-600">Edit Status</a>
                            <p class="inline-block px-2 py-1 mr-3 text-white rounded-full" :class="setColor(request.status)">{{ request.status }}</p>
                            <div @click="showDetails(request.id)" class="bg-white px-2 py-1 rounded-full transition hover:bg-gray-300 flex justify-center items-center">
                            <i class="fas fa-angle-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between p-2">
                    <p>Time Requested</p>
                    <p>{{ request.datetime.toDate().toDateString() + ' ' + request.datetime.toDate().toLocaleTimeString('en-US') }}</p>
                    </div>
                    <div class="flex items-center justify-between p-2 bg-gray-100 rounded">
                    <p>End</p>
                    <p>*End Time*</p>
                    </div>
                    <div class="flex items-center justify-between p-2">
                    <p>Phone</p>
                    <p>{{ request.number }}</p>
                    </div>
                    <div class="flex items-center justify-between p-2 bg-gray-100 rounded">
                    <p>Location</p>
                    <p>{{ request.location }}</p>
                    </div>
                    <div class="flex items-center justify-between p-2">
                    <p>Model</p>
                    <p>{{ request.model }}</p>
                    </div>
                    <form v-show="editClicked && currentId === request.id" @submit.prevent="setStatus(request.id)" class="flex items-center">
                    <select v-model="form.status" class="w-3/4 border py-1 px-2 mr-2" required>
                        <option value="Not Started">Not Started</option>
                        <option value="Started">Started</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                    </select>
                    <input type="submit" class="w-1/4 bg-green-500 text-white font-bold rounded-sm mr-2 py-1 transition hover:bg-green-600">
                    </form>
                </div>

                <div v-show="detailsClicked && currentId === request.id" class="flex flex-col">
                    <div class="flex items-center justify-between font-bold text-gray-700 border-b pt-2 pb-3 mb-3">
                        <div @click="detailsClicked = !detailsClicked" class="transition hover:text-gray-500">
                            <i class="fas fa-angle-left mr-2"></i>
                            Return
                        </div>
                        <p class="uppercase">{{ request.first + ' ' + request.last }}</p>
                        <div class="flex items-center">
                            <p class="uppercase mr-3">Status:</p>
                            <p class="inline-block px-2 py-1 mr-3 text-white rounded-full" :class="setColor(request.status)">{{ request.status }}</p>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex flex-col mb-3">
                            <p class="uppercase mb-1">Contact Information</p>
                            <div class="flex items-center justify-between">
                                <p>Mobile Phone</p>
                                <p>{{ request.number }}</p>
                            </div>
                            <div class="flex items-center justify-between">
                                <p>Email</p>
                                <p>{{ request.email }}</p>
                            </div>
                        </div>
                        <div class="flex flex-col mb-3">
                            <p class="uppercase mb-1">Vehicle Information</p>
                            <div class="flex items-center justify-between">
                                <p>Model</p>
                                <p>{{ request.model }}</p>
                            </div>
                            <div class="flex items-center justify-between">
                                <p>License Plate</p>
                                <p>{{ request.license }}</p>
                            </div>
                            <div class="flex items-center justify-between">
                                <p>Vehicle Description</p>
                                <p>{{ request.vehicle_desc }}</p>
                            </div>
                        </div>
                        <div class="flex flex-col mb-3">
                            <p class="uppercase mb-1">Request Information</p>
                            <div class="flex items-center justify-between">
                                <p>Time Sent</p>
                                <p>{{ request.datetime.toDate().toDateString() + ' ' + request.datetime.toDate().toLocaleTimeString('en-US') }}</p>
                            </div>
                            <div class="flex items-center justify-between">
                                <p>Location</p>
                                <p>{{ request.location }}</p>
                            </div>
                            <div class="flex items-center justify-between">
                                <p>Problem Description</p>
                                <p>{{ request.problem_desc }}</p>
                            </div>
                        </div>

                        <div class="flex items-center justify-between border-t pt-2 pb-3 mb-3">
                            <p class="uppercase mb-1">Estimated Time of Arrival (ETA)</p>
                            <form @submit.prevent="setEta(request.id)" class="flex items-center">
                                <input v-model="form.eta" type="datetime-local" placeholder="Enter ETA" class="border border-gray-300 py-1 px-2 mr-2" required>
                                <input type="submit" class="py-1 px-2  bg-gray-300 transition hover:bg-gray-400">
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import db from '../firebase';
    import { doc, getDocs, updateDoc, collection } from 'firebase/firestore';

    export default {
        name: 'ServicesAdminView',
        data() {
            return {
                services: null,
                servicesArr: [],
                currentId: null,
                editClicked: null,
                detailsClicked: null,
                form: {
                    status: 'Not Started',
                    statusStarted: null,
                    statusIP: null,
                    statusCompleted: null,
                    statusCancelled: null,
                    eta: null,
                },
            }
        },
        created() {
            const servicesCollection = collection(db, 'service-requests');
            this.services = servicesCollection;

            getDocs(servicesCollection)
            .then((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    this.servicesArr.push({
                    ...doc.data(), id: doc.id
                    });
                })
            })
            .catch(err => {
                console.log(err.message);
            })
        },
        methods: {
            async setStatus(currentId) {
                let currentRequest = doc(db, 'service-requests', currentId);

                try {
                    await updateDoc(currentRequest, {
                        status: this.$data.form.status,
                    });

                    this.setEditTime(this.form.status, currentRequest);

                    alert("Status updated!");
                } catch(err) {
                    console.log(err.message);
                }
            },
            async setEditTime(value, request) {
                if (value === 'Started') {
                    await updateDoc(request, {
                        statusStarted: new Date()
                    });
                }
                else if (value === 'In Progress') {
                    await updateDoc(request, {
                        statusIP: new Date()
                    });
                }
                else if (value === 'Completed') {
                    await updateDoc(request, {
                        statusCompleted: new Date()
                    });
                }
                else if (value === 'Cancelled') {
                    await updateDoc(request, {
                        statusCancelled: new Date()
                    });
                }
            },
            async setEta(currentId) {
                let currentRequest = doc(db, 'service-requests', currentId); 
                
                try {
                    await updateDoc(currentRequest, {
                        eta: new Date(this.$data.form.eta)
                    });

                    alert("ETA updated!");
                } catch(err) {
                    console.log(err.message);
                }
            },
            setColor(status) {
                if (status === 'Not Started' || status === 'Cancelled') {
                    return 'bg-red-500';
                }
                else if (status === 'Started' || status === 'Completed') {
                    return 'bg-green-500';
                }
                else if (status === 'In Progress') {
                    return 'bg-yellow-500';
                }
                else {
                    return 'bg-gray-500';
                }
            },
            showEdit(currentId) {
                this.currentId = currentId;

                this.editClicked = true;
            },
            showDetails(currentId) {
                this.currentId = currentId;

                this.detailsClicked = !this.detailsClicked;
            },
        }
    };

</script>
