<template>
  <h3>AboutView</h3>
</template>

<script>

export default {
  name: 'AboutView',
};
</script>
