<template>

  <div v-if="isMobile">
        
    <div class="fixed overflow-y-auto w-full h-5/6 bottom-0 mt-5 px-5 py-4 text-gray-900 bg-white shadow-md rounded-t-2xl z-10">
      <!-- <SearchBar placeholder="Search charging stations" /> -->
      <div class="col-span-full flex justify-center items-center">
        <form v-if="!formSubmitted" @submit.prevent="searchZip()" class="lg:grid grid-cols-2 gap-4">
          <div class="col-span-2">
            <div class="mb-3">
              <label for="zipcode" class="block mb-1">Search zipcode</label>
              <textarea v-model="zipcode" ref="zipcode" rows="1" class="border w-full" required></textarea>
            </div>
          </div>
          <div class="col-start-2">
            <button type="submit" class="w-full py-2 rounded shadow-md bg-yellow-500 transition hover:bg-yellow-600 text-white font-bold">Submit</button>
          </div>
        </form>
      </div>
      <div class="col-span-full flex justify-center items-center m-1"><p class="text-sm text-red-600">{{searchError}}</p></div>
      <div v-for="(station, i) in stations" :key="station.id" class="px-3 pt-2 border-b-2 border-dotted">
        <ul>
          <li class="font-bold">{{ station.background.name }}</li>
          <li>{{ station.background.company }}</li>
          <li class="mb-4">{{ station.background.address }}, {{ station.background.city }}, {{ station.background.state }} {{ station.background.zipcode }}</li>
          <li class="text-sm text-gray-600 mb-2">Opens {{ station.background.time }} | Charging {{ station.background.type }} | {{ station.status.rating }} Stars</li>
        </ul>
        <div class="flex items-center justify-between text-white">
          <button @click="setId(station.id), stationRouteShown=false, currentIndex=i" class="w-1/2 bg-blue-500 border text-sm py-1 transition hover:bg-blue-600 font-bold">
            <i class="fas fa-angle-down mr-2"></i>
            Show details
          </button>
<!--          <button @click="setId(station.id), stationRouteShown=true, stationDetailsShown=false, currentIndex=i" class="w-1/2 bg-green-500 border text-sm py-1 transition hover:bg-green-600 font-bold"> -->
          <button @click="showRoute(station.id), stationRouteShown=true, stationDetailsShown=false, currentIndex=i" class="w-1/2 bg-green-500 border text-sm py-1 transition hover:bg-green-600 font-bold">
            <i class="fas fa-route mr-2"></i>
            Show route
          </button>
        </div>

        <div v-if="station.id === currentId">
          <div v-show="stationClicked && stationDetailsShown" class="px-3 bg-blue-50 text-sm">

            <div @click="closeDetails" class="flex items-center justify-end pt-2">
              <i class="fas fa-times"></i>
            </div>
            <div class="pb-2 border-b">
              <p class="font-bold">Background</p>
              <ul>
                <li>Station Name: {{ station.background.name }}</li>
                <li>Company: {{ station.background.company }}</li>
                <li>Address: {{ station.background.address }}</li>
                <li>Charging Type: {{ station.background.type }}</li>
                <li>Access Time: {{ station.background.time }}</li> 
              </ul>
            </div>
            <div class="py-2 border-b">
              <p class="font-bold">Status</p>
              <ul>
                <li>Status Date: {{ station.status.date }}</li>
                <li>Station Rating: {{ station.status.rating }}</li>
                <li>Station Condition: {{ station.status.condition }}</li>
              </ul>
              <button @click="showStatus(station.id)" class="text-blue-500 transition hover:text-blue-700 mb-1 underline">See User Ratings & Conditions</button>  
              <div class="flex items-center space-between text-sm">
                <button @click="showAddStatus()" class="bg-yellow-500 transition hover:bg-yellow-600 px-2 py-1 mr-2 shadow-sm text-white font-bold rounded">Add Rating & Condition</button>   
              </div>
            </div>
            <div class="py-2 border-b">
              <p class="font-bold">Pricing</p>
              <ul>
                <li>Listed Price: {{ station.pricing.listed }}</li>
                <li>Actual Price: {{ station.pricing.actual }}</li>
              </ul>     
              <button @click="showAddPricing()" class="bg-yellow-500 transition hover:bg-yellow-600 px-2 py-1 mr-2 shadow-sm text-white text-sm font-bold rounded">Add Pricing</button>      
            </div>
            <div class="py-2 mb-3">
              <p class="font-bold">Comments</p>
              <ul>
                <li>User Feedback: {{ station.comments.user }}</li>
                <li>General Feedback: {{ station.comments.general }}</li>
              </ul>
              <button @click="showAddFeedback()" class="bg-yellow-500 transition hover:bg-yellow-600 px-2 py-1 mr-2 shadow-sm text-white text-sm font-bold rounded">Add Feedback</button>    
            </div>
          </div>

<!--          <div v-show="stationRouteShown" class="bg-blue-50 text-sm">
            <div @click="stationRouteShown=false" class="flex items-center justify-end pt-2">
              <i class="fas fa-times"></i>
            </div>
            <div class="relative -left-12">
              <MapView :station="stations[currentIndex]" :key="currentIndex" :currentLat="currentLat" :currentLng="currentLng" />
            </div>
          </div> -->
          <div v-show="routeClicked && !stationDetailsShown" class="object-cover h-100 w-full">
            <div @click="routeClicked=false" class="flex items-center justify-end">
              <i class="fas fa-times"></i>
            </div>
            <MapView :station="stations[currentIndex]" :key="currentIndex" :currentLat="currentLat" :currentLng="currentLng" class="object-cover h-80 w-full" />

          </div>
          <div v-if="stationStatusShown">
            <div class="pt-2 px-2 mb-1">
              <div class="flex flex-col">
                <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                  <i class="fas fa-arrow-left mr-1"></i>
                  <p>Return</p>
                </div>
                <p class="font-bold">User Ratings & Conditions</p>
                <p>{{ station.background.name }}</p>
              </div>
            </div>
                            
            <div class="px-2 border-t-2 border-dotted">
              <ul v-for="status in statuses" :key="status" class="py-2 border-b-2 border-dotted">
                <li v-for="i in status.rating" :key="i" class="inline mr-1">
                  <i class="fas fa-star text-yellow-500"></i>
                </li>
                <li class="inline text-sm text-gray-600">| {{ status.rating }} Star(s) </li>    
                <li>{{ status.condition }}</li>
                <li>{{ status.review }}</li>
              </ul>
            </div>
          </div>

          <div v-if="addStatusShown">

            <div class="pt-2 pb-3 px-2">
              <div class="flex flex-col">
                <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                  <i class="fas fa-arrow-left mr-1"></i>
                  <p>Return</p>
                </div>
                <p class="font-bold">Add Rating & Condition</p>
                <p class="mb-1">{{ station.background.name }}</p>
              </div>

              <ul>
                <form @submit.prevent="addStatus(station.id)" class="pt-1">
                  <input v-model="form.rating" type="number" min="0" max="5" placeholder="Enter rating (1-5)" class="w-full border py-1 px-2 mb-2" required>
                  <!-- <input v-model="form.condition" type="text" placeholder="Enter condition" class="w-full border py-1 px-2 mb-2" required> -->
                  <select v-model="form.condition" class="w-full border py-1 px-2 mb-2" required>
                    <option value="Great Condition">Great Condition</option>
                    <option value="Good Condition">Good Condition</option>
                    <option value="Decent Condition">Decent Condition</option>
                    <option value="Poor Condition">Poor Condition</option>
                    <option value="Very Poor Condition">Very Poor Condition</option>
                  </select>
                  <!-- <textarea v-model="form.review" placeholder="Enter comment" class="w-full border py-1 px-2 mr-2" required></textarea> -->
                  <input type="submit" class="w-1/3 border py-1 px-2 transition hover:bg-gray-200">
                </form>
              </ul>
            </div>
        </div>

        <div v-else-if="addPricingShown">
          <div class="pt-2 pb-3 px-2">
            <div class="flex flex-col">
              <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                <i class="fas fa-arrow-left mr-1"></i>
                <p>Return</p>
              </div>
              <p class="font-bold">Add Pricing</p>
              <p class="mb-1">{{ station.background.name }}</p>
            </div>
            <form @submit.prevent="addPricing(station.id)" class="pt-1 flex items-center">
              <input v-model="formPricing.price" type="number" step="0.01" placeholder="Enter pricing" class="w-3/4 border py-1 px-2 mr-1" required>
              <input type="submit" class="w-1/4 border py-1 px-2 transition hover:bg-gray-200">
            </form>
          </div>
        </div>
        <div v-else-if="addFeedbackShown">
          <div class="pt-2 pb-3 px-2">
            <div class="flex flex-col">
              <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                <i class="fas fa-arrow-left mr-1"></i>
                <p>Return</p>
              </div>
              <p class="font-bold">Add Feedback</p>
              <p class="mb-1">{{ station.background.name }}</p>
            </div>
            <form @submit.prevent="addFeedback(station.id)" class="pt-1">
              <textarea v-model="formFeedback.feedback" placeholder="Enter feedback" class="w-full border py-1 px-2 mr-1" required name="feedback" id="" cols="5" rows="5"></textarea>
              <input type="submit" class="w-1/4 border py-1 px-2 transition hover:bg-gray-200">
            </form>
          </div>
        </div>
        </div>

      </div>

    </div>

  </div>

  <div v-else class="fixed overflow-y-auto w-1/5 top-16 left-0 inset-y-0 my-5 ml-4 hidden lg:block px-5 py-4 text-gray-900 bg-white shadow-md rounded z-10">
    <!-- <input type="text" placeholder="Search charging stations" class="w-full border py-1 px-2 mb-4"> -->
    <div class="col-span-full flex justify-center items-center">
      <form v-if="!formSubmitted" @submit.prevent="searchZip()" class="lg:grid grid-cols-2 gap-4">
        <div class="col-span-2">
          <div class="mb-3">
            <label for="zipcode" class="block mb-1">Search zipcode</label>
            <textarea v-model="zipcode" ref="zipcode" rows="1" class="border w-full" required></textarea>
          </div>
        </div>
        <div class="col-span-2">
          <button type="submit" class="w-full py-2 rounded shadow-md bg-yellow-500 transition hover:bg-yellow-600 text-white font-bold">Submit</button>
        </div>
      </form>
    </div>
    <div class="col-span-full flex justify-center items-center m-1"><p class="text-sm text-red-600">{{searchError}}</p></div>
  
    <div v-for="(station, i) in stations" :key="station.id" @click="setId(station.id), currentIndex=i" class="py-2 px-2 border-b-2 border-dotted transition hover:bg-gray-100">
      <ul>
        <li class="font-bold">{{ station.background.name }}</li>
        <li>{{ station.background.company }}</li>
        <li class="mb-4">{{ station.background.address }}, {{ station.background.city }}, {{ station.background.state }} {{ station.background.zipcode }}</li>
        <li class="text-sm text-gray-600">Opens {{ station.background.time }} | Charging {{ station.background.type }} | {{ station.status.rating }} Stars</li>
      </ul>
    </div>

    <transition name="station-details">
      <div v-if="stationClicked" class="fixed overflow-y-auto w-1/5 top-16 right-0 inset-y-0 my-5 mr-4 hidden lg:block px-5 py-4 text-gray-900 bg-white shadow-md rounded">
        <div class="flex justify-end">
          <i @click="closeDetails" class="fas fa-times transition hover:text-gray-500"></i>
        </div>
        
        <div v-for="station in stations" :key="station.id">
          <div v-if="station.id === currentId">
            
            <div v-if="stationDetailsShown">
              <div class="py-2 px-2 border-b-2 border-dotted">
                <p class="font-bold">Background</p>
                <ul>
                  <li>Station Name: {{ station.background.name }}</li>
                  <li>Company: {{ station.background.company }}</li>
                  <li>Address: {{ station.background.address }}</li>
                  <li>Charging Type: {{ station.background.type }}</li>
                  <li>Access Time: {{ station.background.time }}</li> 
                </ul>
              </div>
              <div class="py-2 px-2 border-b-2 border-dotted">
                <p class="font-bold">Status</p>
                <ul>
                  <li>Status Date: {{ station.status.date }}</li>
                  <li>Station Rating: {{ station.status.rating }}</li>
                  <li>Station Condition: {{ station.status.condition }}</li>
                </ul>
                <button @click="showStatus(station.id)" class="text-blue-500 transition hover:text-blue-700 mb-2 underline">See User Ratings & Conditions</button>  
                <div class="flex items-center space-between text-sm">
                  <button @click="showAddStatus()" class="bg-yellow-500 transition hover:bg-yellow-600 px-2 py-1 mr-2 shadow-sm text-white font-bold rounded">Add Rating & Condition</button>   
                </div>
              </div>
              <div class="py-2 px-2 border-b-2 border-dotted">
                <p class="font-bold">Pricing</p>
                <ul>
                  <li>Listed Price: {{ station.pricing.listed }}</li>
                  <li>Actual Price: {{ station.pricing.actual }}</li>
                </ul>     
                <button @click="showAddPricing()" class="bg-yellow-500 transition hover:bg-yellow-600 px-2 py-1 mr-2 shadow-sm text-white text-sm font-bold rounded">Add Pricing</button>      
              </div>
              <div class="py-2 px-2 border-b-2 border-dotted">
                <p class="font-bold">Comments</p>
                <ul>
                  <li>User Feedback: {{ station.comments.user }}</li>
                  <li>General Feedback: {{ station.comments.general }}</li>
                </ul>
                <button @click="showAddFeedback()" class="bg-yellow-500 transition hover:bg-yellow-600 px-2 py-1 mr-2 shadow-sm text-white text-sm font-bold rounded">Add Feedback</button>    
              </div>
              <div class="py-2 px-2 border-b-2 border-dotted">
                <p class="font-bold">Images</p>
                <ul>
                  <li>*Add images here?*</li>
                </ul>  
              </div>
            </div>

            <div v-if="stationStatusShown">
              <div class="pt-2 px-2 mb-1">
                <div class="flex flex-col">
                  <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                    <i class="fas fa-arrow-left mr-1"></i>
                    <p>Return</p>
                  </div>
                  <p class="font-bold">User Ratings & Conditions</p>
                  <p>{{ station.background.name }}</p>
                </div>
              </div>
                              
              <div class="px-2 border-t-2 border-dotted">
                <ul v-for="status in statuses" :key="status" class="py-2 border-b-2 border-dotted">
                  <li v-for="i in status.rating" :key="i" class="inline mr-1">
                    <i class="fas fa-star text-yellow-500"></i>
                  </li>
                  <li class="inline text-sm text-gray-600">| {{ status.rating }} Star(s) </li>    
                  <li>{{ status.condition }}</li>
                  <li>{{ status.review }}</li>
                </ul>
              </div>
            </div>

            <div v-if="addStatusShown">

              <div class="pt-2 pb-3 px-2">
                <div class="flex flex-col">
                  <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                    <i class="fas fa-arrow-left mr-1"></i>
                    <p>Return</p>
                  </div>
                  <p class="font-bold">Add Rating & Condition</p>
                  <p class="mb-1">{{ station.background.name }}</p>
                </div>

                <ul>
                  <form @submit.prevent="addStatus(station.id)" class="pt-1">
                    <input v-model="form.rating" type="number" min="0" max="5" placeholder="Enter rating (1-5)" class="w-full border py-1 px-2 mb-2" required>
                    <!-- <input v-model="form.condition" type="text" placeholder="Enter condition" class="w-full border py-1 px-2 mb-2" required> -->
                    <select v-model="form.condition" class="w-full border py-1 px-2 mb-2" required>
                      <option value="Great Condition">Great Condition</option>
                      <option value="Good Condition">Good Condition</option>
                      <option value="Decent Condition">Decent Condition</option>
                      <option value="Poor Condition">Poor Condition</option>
                      <option value="Very Poor Condition">Very Poor Condition</option>
                    </select>
                    <!-- <textarea v-model="form.review" placeholder="Enter comment" class="w-full border py-1 px-2 mr-2" required></textarea> -->
                    <input type="submit" class="w-1/3 border py-1 px-2 transition hover:bg-gray-200">
                  </form>
                </ul>
              </div>
            </div>

            <div v-else-if="addPricingShown">
              <div class="pt-2 pb-3 px-2">
                <div class="flex flex-col">
                  <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                    <i class="fas fa-arrow-left mr-1"></i>
                    <p>Return</p>
                  </div>
                  <p class="font-bold">Add Pricing</p>
                  <p class="mb-1">{{ station.background.name }}</p>
                </div>
                <form @submit.prevent="addPricing(station.id)" class="pt-1 flex items-center">
                  <input v-model="formPricing.price" type="number" step="0.01" placeholder="Enter pricing" class="w-3/4 border py-1 px-2 mr-1" required>
                  <input type="submit" class="w-1/4 border py-1 px-2 transition hover:bg-gray-200">
                </form>
              </div>
            </div>

            <div v-else-if="addFeedbackShown">
              <div class="pt-2 pb-3 px-2">
                <div class="flex flex-col">
                  <div @click="returnDetails(isMobile)" class="flex items-center mb-1 transition hover:text-gray-500">
                    <i class="fas fa-arrow-left mr-1"></i>
                    <p>Return</p>
                  </div>
                  <p class="font-bold">Add Feedback</p>
                  <p class="mb-1">{{ station.background.name }}</p>
                </div>
                <form @submit.prevent="addFeedback(station.id)" class="pt-1">
                  <textarea v-model="formFeedback.feedback" placeholder="Enter feedback" class="w-full border py-1 px-2 mr-1" required name="feedback" id="" cols="5" rows="5"></textarea>
                  <input type="submit" class="w-1/4 border py-1 px-2 transition hover:bg-gray-200">
                </form>
              </div>
            </div>

          </div>
        </div>
    
      </div>  
      
    </transition>

  </div>
  <div id="background" class="p-1 my-10" v-show="!isMobile" >
    <MapView :station="stations[currentIndex]" :key="currentIndex" :currentLat="currentLat" :currentLng="currentLng" />
  </div>
</template>

<script>
import db from '../firebase';
import { doc, getDocs, addDoc, updateDoc, collection, query, where } from 'firebase/firestore';
// import SearchBar from './SearchBar.vue';
import MapView from './MapView.vue';
import axios from 'axios';

export default {
  name: 'ScrollableMenu',
  components: {
    // SearchBar,
    MapView,
},
  props: {
    isMobile: Boolean,
    // isClicked: Boolean,
  },
  data() {
    return {
      stations: [],
      statuses: [],
      currentId: null,
      currentIndex: null,
      currentLat: null,
      currentLng: null,
      stationClicked: null,
      stationDetailsShown: null,
      stationRouteShown: null,
      stationStatusShown: null,
      addStatusShown: null,
      addPricingShown: null,
      addFeedbackShown: null,
      routeClicked: null,
      form: {
        rating: null,
        condition: 'Great Condition',
      },
      formPricing: {
        price: null,
      },
      formFeedback: {
        feedback: null,
      },
      zipcode: null,
      searchError: null,
    }
  },
  created() {
    // const stationsCollection = collection(db, 'charging-stations-test');
    if('geolocation' in navigator){
      navigator.geolocation.getCurrentPosition(
      (position) => {
      console.log(">>>>>>>>>>>>Current Position: ", position);
      this.currentLat = position.coords.latitude;
      this.currentLng = position.coords.longitude;
      console.log('Current lat: ', this.currentLat);
      console.log('Current lng: ', this.currentLng);

      axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.currentLat},${this.currentLng}&key=AIzaSyCSLoEe5amg_DqF7t-XQnFVtSrUFtksZS0`)
        .then((response) => {
          if (response.data.error_message) {
            console.log('Map response error', response.data.error_message);
          } else {
            console.log('map address-', response.data.results[0].address_components[6].short_name);
            this.zipcode = response.data.results[0].address_components[6].short_name;
            this.searchZip();
          }
        })
        .catch((error) => {
          // console.log('Map error', error.message);
          this.login_alert_msg = error.message;
        });
      console.log(">>>>Zipcode", this.zipcode);
//      const stationsCollection = query(collection(db, 'charging-stations-test'), where("background.zipcode", "==", this.zipcode));
//      const querySnapshot = getDocs(stationsCollection);
      // this.stations = [];
//      querySnapshot.forEach((doc) => {

//      getDocs(stationsCollection)
//        .then((snapshot) => {
//          snapshot.docs.forEach((doc) => {
//            var distance = 0.0;
//            if (this.currentLat != null) {
//              distance = this.getDistance(doc.data().coordinates[0], doc.data().coordinates[1], this.currentLat, this.currentLng);
              // console.log('Distance', distance, doc.data(), doc.data().coordinates[0], doc.data().coordinates[1], this.currentLat, this.currentLng);
//            }
//            if (distance < 0.1) {
//              this.stations.push({
//                ...doc.data(), id: doc.id, distance: distance
//              });
//            }
//          })
//          this.stations = this.sort_by_key(this.stations, 'distance');
//          console.log('Stations sorted: ', this.stations);
//        })
//        .catch(err => {
//          console.log(err.message);
//        })
      })
    }
      // this.stationListShown = true;
    },
    methods: {
      getCurrentCoord() {
        if('geolocation' in navigator){
          navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(">>>>>>>>>>>>Current Position: ", position);
            this.currentLat = position.coords.latitude;
            this.currentLng = position.coords.latitude;
            console.log('Current lat 2: ', this.currentLat);
            console.log('Current lng 2: ', this.currentLng);
            let loc = {latitude: position.coords.latitude, longitude: position.coords.latitude};
            console.log('Loc', loc);
            return Promise.all(loc);
          })
        }
      },
      getDistance(x1, y1, x2, y2){
        let y = x2 - x1;
        let x = y2 - y1;
        return Math.sqrt(x * x + y * y);
      },
      sort_by_key(array, key){
      return array.sort(function(a, b)
      {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
      },
      closeDetails() {
        this.stationClicked = false;
        this.stationDetailsShown = false;
      },
      closeDetailsMobile() {
        this.mobileDetailsShown = false;
      },
      returnDetails(isMobile) {
        if (isMobile) {
          this.stationStatusShown = false;
          this.addStatusShown = false;
          this.addPricingShown = false;
          this.addFeedbackShown = false;
          this.stationDetailsShown = false;
          this.stationListShown = true;
        }
        else {
          this.stationStatusShown = false;
          this.addStatusShown = false;
          this.addPricingShown = false;
          this.addFeedbackShown = false;
          this.stationDetailsShown = true;
        }
      },
      setId(currentId) {
        this.currentId = currentId; 
        this.stationClicked = true;
        this.stationDetailsShown = true;

        this.stationListShown = false;
        this.stationStatusShown = false;
        this.addStatusShown = false;
        this.addPricingShown = false;
        this.addFeedbackShown = false;
      },
      showStatus(stationId) {
        let statusesCollection = collection(db, 'charging-stations-test', `${stationId}`, 'statuses');

        this.stationListShown = false;
        this.stationDetailsShown = false;
        this.addStatusShown = false;
        this.addPricingShown = false;
        this.addFeedbackShown = false;
        this.stationStatusShown = true;

        if (this.statuses.length > 0) {
          this.statuses = [];
        }
        
        getDocs(statusesCollection)
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              this.statuses.push({
                  ...doc.data(), id: doc.id
              })
            })
          }) 
      },
      showAddStatus() {
        this.stationDetailsShown = false;
        this.stationStatusShown = false;
        this.addPricingShown = false;
        this.addFeedbackShown = false;
        this.addStatusShown = true;
      },
      showAddPricing() {
        this.stationDetailsShown = false;
        this.stationStatusShown = false;
        this.addStatusShown = false;
        this.addFeedbackShown = false;
        this.addPricingShown = true;
      },
      showAddFeedback() {
        this.stationDetailsShown = false;
        this.stationStatusShown = false;
        this.addStatusShown = false;
        this.addPricingShown = false;
        this.addFeedbackShown = true;
      },
      showRoute(currentId) {
        this.currentId = currentId;
        this.routeClicked = true;
      },
      async addStatus(stationId) {
        let statusesCollection = collection(db, 'charging-stations-test', `${stationId}`, 'statuses');
        await addDoc(statusesCollection, this.$data.form);
      },
      async addPricing(stationId) {
        // let pricingCollection = collection(db, 'charging-stations-test', `${stationId}`, 'pricing');
        // await addDoc(pricingCollection, this.$data.formPricing);
        let pricingCollection = doc(db, 'charging-stations-test', `${stationId}`);
        await updateDoc(pricingCollection, {
          pricing: { actual: this.$data.formPricing.key }
        });
      },
      async addFeedback(stationId) {
        let feedbackCollection = collection(db, 'charging-stations-test', `${stationId}`, 'feedback');
        await addDoc(feedbackCollection, this.$data.formFeedback);
      },
      async searchZip() {
        console.log("Searching by zip", this.zipcode);
        this.searchError = '';
        const stationsCollection = query(collection(db, 'charging-stations-test'), where("background.zipcode", "==", this.zipcode));
        const querySnapshot = await getDocs(stationsCollection);
        console.log("Station in zipcode", querySnapshot);
        this.stations = [];
        querySnapshot.forEach((doc) => {

            var distance = 0.0;
            if (this.currentLat != null) {
              distance = this.getDistance(doc.data().coordinates[0], doc.data().coordinates[1], this.currentLat, this.currentLng);
              console.log('Distance', distance, doc.data(), doc.data().coordinates[0], doc.data().coordinates[1], this.currentLat, this.currentLng);
            }
            // if (distance < 0.1) {

            this.stations.push({
              ...doc.data(), id: doc.id, distance: distance
            });
            // }
          });
          this.stations = this.sort_by_key(this.stations, 'distance');
          console.log('Stations sorted: ', this.stations);
        if (this.stations.length == 0) {
          this.searchError = 'No results found for zip ' + this.zipcode;
        }
      },
    }
  }

</script>

<style scoped>
  .station-details-enter-active,
  .station-details-leave-active {
    transition: 0.3s ease-in-out;
  }

  .station-details-enter-from,
  .station-details-leave-to
  {
    transform: translateX(400px);
  }

  .station-details-enter-to {
    transform: translateX(0px);
  }

  .station-mobile-enter-active,
  .station-mobile-leave-active {
    transition: 0.6s ease-in-out;
  }

  .station-mobile-enter-from
  {
    transform: translateY(800px);
  }
  
  .station-mobile-enter-to
  {
    transform: translateY(150px);
  }

  #background {
    /* background: url('../assets/google-map-background.jpg') no-repeat center center/cover; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.7;
  }

</style>
