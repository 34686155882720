<template>

  <div class="col-span-3">
    
    <div class="flex flex-col items-center pt-2 pb-10 px-6 font-bold">
      <h3 class="text-2xl pb-2">{{ `Request ${formSubmitted ? 'Submitted' : 'a Service'}` }}</h3>
      <p class="text-gray-600 text-center">{{ `${formSubmitted ? 'We will be with you soon.' : 'Need help? Fill out the form and someone will be right with you.'}` }}</p>
    </div>

    <div class="bg-white rounded-md shadow-md lg:px-12 px-7 py-8 lg:mx-0 mx-5">
      <form v-if="!formSubmitted" @submit.prevent="addRequest()" class="lg:grid grid-cols-2 gap-4">

       <div>         
         <div class="mb-3">
           <label for="firstname" class="block mb-1">First name*</label>
           <input v-model="form.first" ref="first" type="text" class="w-full border py-2 px-3" required>
         </div>
         <div class="mb-3">
           <label for="email" class="block mb-1">Email</label>
           <input v-model="form.email" ref="email" type="email" class="w-full border py-2 px-3">
         </div>
         <div class="mb-3">
           <label for="text" class="block mb-1">Current location*</label>
           <input v-model="form.location" ref="location" type="text" class="w-full border py-2 px-3" required>
         </div>
         <div class="mb-3">
           <label class="block mb-1">Vehicle model*</label>
           <select v-model="form.model" ref="model" class="w-full border py-2 px-3" required>
             <option value="Vehicle Model 1">Model 1</option>
             <option value="Vehicle Model 2">Model 2</option>
             <option value="Vehicle Model 3">Model 3</option>
           </select>
         </div>
       </div>
      
        <div>
          <div class="mb-3">
            <label for="lastname" class="block mb-1">Last name*</label>
            <input v-model="form.last" ref="last" type="text" class="w-full border py-2 px-3" required>
          </div>
          <div class="mb-3">
            <label for="number" class="block mb-1">Phone number*</label>
            <input v-model="form.number" ref="number" type="tel" class="w-full border py-2 px-3" required>
          </div>
          <div class="mb-3">
            <label for="text" class="block mb-1">License plate</label>
            <input v-model="form.license" ref="license" type="text" class="w-full border py-2 px-3">
          </div>
          <div class="mb-3">
            <label for="text" class="block mb-1">Vehicle images</label>
            <input type="text" class="w-full border py-2 px-3" placeholder="Will change later">
          </div>
        </div>
       
        <div class="col-span-2">
          <div class="mb-3">
            <label for="vehicle" class="block mb-1">Description of vehicle*</label>
            <textarea v-model="form.vehicle_desc" ref="vehicle_desc" rows="4" class="border w-full" required></textarea>
          </div>
          <div class="lg:mb-0 mb-3">
            <label for="problem" class="block mb-1">Description of problem*</label>
            <textarea v-model="form.problem_desc" ref="problem_desc" rows="4" class="border w-full" required></textarea>
          </div>
        </div>

        <div class="col-start-2">
          <button type="submit" class="w-full py-2 rounded shadow-md bg-yellow-500 transition hover:bg-yellow-600 text-white font-bold">Submit</button>
        </div>
      </form>

      <div v-else>
        <div @click="getDetails()" class="flex items-center font-bold transition hover:text-gray-500">        
          <p class="mr-2 uppercase">Edit Request Details</p>
          <i class="fas fa-angle-down"></i>
        </div>

        <div v-show="editClicked">
        hi
        </div>
      </div>
    </div>

    <!-- <div class="bg-white rounded-md shadow-md border lg:px-12 px-7 py-8 lg:mx-0 mx-5 mt-8">
      <div class="flex flex-col">
        <p class="uppercase font-bold mb-1">Current Status</p>
        <div class="flex items-center justify-between">
          <p>Estimated Time of Arrival (ETA)</p>
        </div>
      </div>
    </div> -->
  
  </div>

</template>

<script>

  import db from '../firebase';
  import { doc, addDoc, getDoc, collection } from 'firebase/firestore';

  export default {
    name: 'ServicesView',
    data() {
      return {
        services: collection(db, 'service-requests'),
        eta: null,
        formSubmitted: false,
        editClicked: null,
        form: {
          first: null,
          last: null,
          email: null,
          number: null,
          location: null,
          license: null,
          model: 'Model 1',
          vehicle_desc: null,
          problem_desc: null,
          status: null,
          datetime: null,
        },
      }
    },
    methods: {
      async addRequest() {
        try {
          this.$data.form.status = 'Not Started';
          this.$data.form.datetime = new Date();

          await addDoc(this.services, this.$data.form);
          this.formSubmitted = true;

          alert("Request submitted!");
        } catch(err) {
          console.log(err.message);
        }
      },
      async getDetails() {
        let userRef = doc(db, 'users', 'liza');
        let userDoc = await getDoc(userRef);

        console.log(userDoc.data().sref)
      },
    }  
  };

</script>